
body {
  font-family: alwyn-new-rounded-web, sans-serif;
font-weight: 400;
font-style: normal;
  color:#302e63;
}

body{
  background-color: #ffffff;
}
.logo{
  width: 18rem;
  padding: 20px;


  }
.minilogo{
  margin-left:auto;
  margin-right:auto;
  display: block;
}
.button_link{
  text-decoration: none;
  color: #ee7427;
  }
.main_col{
  background-color:#fff;
padding-top:5%;
padding-bottom:5%;
border-radius: 20px;
box-shadow:  5px 5px 10px rgba(255,255,255,0.1),
-5px -5px 10px #f1f3f6;

-moz-box-shadow:
5px 5px 10px rgba(255,255,255,0.1),
-5px -5px 10px #f1f3f6;

box-shadow:
5px 5px 10px rgba(255,255,255,0.1),
-5px -5px 10px #f1f3f6;
}

p, .zusatz{
  text-align: center;
}


i.icon{
  font-size:3em;
  color:#302e63;
  text-align: left;

}

.opacity{
  opacity: 0;
}

.contact{
  width:20%;
  margin-left:auto;
  margin-right:10%;
  background-color: #ffffff;
}
